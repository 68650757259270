import {
  ChangeEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { flushSync } from 'react-dom';

import { useEvent } from '@/hooks/useEvent';
import emojiMartData, { Emoji, EmojiMartData } from '@emoji-mart/data';
import {
  Box,
  Button,
  Divider,
  Flex,
  FloatingPosition,
  FocusTrap,
  Paper,
  Popover,
  PopoverProps,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { SearchIndex, init } from 'emoji-mart';

import { NoData } from '../NoData/NoData';
import { EmojiPickerLocales } from './types';

import styles from './EmojiPicker.module.css';

init({ data: emojiMartData });

export type EmojiPickerProps = {
  value?: string;
  onChange?: (v: string) => void;
  onRemove?: () => void;
  hasIcon?: boolean;
  position?: FloatingPosition;
  popoverProps?: PopoverProps;
  locales?: EmojiPickerLocales;
};

export const EmojiPicker = ({
  value,
  children,
  onChange,
  onRemove,
  hasIcon,
  position = 'bottom-start',
  popoverProps,
  locales = {
    emojis: 'Эмодзи',
    search: 'Поиск',
    searchResults: 'Результат поиска',
    searchNoResults: 'Эмодзи не найдено',
    remove: 'Удалить',
    emojiCategory: {
      frequent: 'Часто используемые',
      people: 'Эмоции и люди',
      nature: 'Животные и природа',
      foods: 'Еда и напитки',
      activity: 'Активности',
      places: 'Путешествия и места',
      objects: 'Предметы',
      symbols: 'Символы',
      flags: 'Флаги',
    },
  },
}: PropsWithChildren<EmojiPickerProps>) => {
  const [opened, setOpened] = useState(false);
  const [query, setQuery] = useState('');

  const [data, setData] = useState<{ id: string; emojis: string[] }[]>([]);

  const [searchData, setSearchData] = useState<string[]>([]);

  const handleItemClick = useEvent<MouseEventHandler<HTMLDivElement>>((e) => {
    const emoji = e.currentTarget.getAttribute('data-emoji');
    if (!emoji) return;
    onChange?.(emoji);
    setOpened(false);
  });

  const handleSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (e) => {
      const query = e.currentTarget.value;
      setQuery(query);

      const emojis = await SearchIndex.search(query);
      setSearchData(
        emojis.map((emoji: Emoji) => {
          return emoji.skins[0].native;
        }),
      );
    },
    [],
  );

  useEffect(() => {
    const { categories, emojis } = emojiMartData as EmojiMartData;

    setData(
      categories.map((c) => ({
        id: c.id,
        emojis: c.emojis.map((e) => emojis[e].skins[0].native),
      })),
    );
  }, []);

  const [maxHeight, setMaxHeight] = useState<number>(500);

  return (
    <Popover
      withinPortal={true}
      opened={opened}
      onChange={setOpened}
      position={position}
      styles={{
        dropdown: {
          padding: 0,
          height: '100%',
          maxHeight: Math.min(maxHeight, 500),
        },
      }}
      middlewares={{
        size: {
          apply({ availableHeight }) {
            flushSync(() => setMaxHeight(availableHeight - 20));
          },
        },
      }}
      {...(popoverProps || {})}
    >
      <Popover.Target>
        <div onClick={() => setOpened(!opened)}>{children ?? value}</div>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap={0} h={'100%'} w={300}>
          <Flex justify={'space-between'} p={8}>
            <Text fw={'bold'} fz={'sm'}>
              {locales.emojis}
            </Text>
            {hasIcon && (
              <Button variant="default" size="compact-sm" onClick={onRemove}>
                {locales.remove}
              </Button>
            )}
          </Flex>
          <Divider />

          <Box p={16} pb={0}>
            <FocusTrap active>
              <TextInput
                leftSection={<IconSearch size={16} />}
                value={query}
                placeholder={locales.search}
                data-autofocus
                onChange={handleSearch}
              />
            </FocusTrap>
          </Box>
          <Box pos={'relative'} flex={1}>
            <Box pos={'absolute'} top={0} left={0} h={'100%'} w={'100%'}>
              <Stack
                pos={'relative'}
                gap={16}
                style={{ overflow: 'auto' }}
                p={16}
                pt={0}
                h={'100%'}
              >
                {query ? (
                  <div>
                    <Paper
                      py={6}
                      pos={'sticky'}
                      top={-1}
                      style={{ zIndex: 1 }}
                      bg={'none'}
                    >
                      <Text fz={'sm'} c={'dimmed'}>
                        {locales.searchResults}
                      </Text>
                    </Paper>
                    {searchData.length ? (
                      <SimpleGrid cols={8} spacing={0}>
                        {searchData.map((emoji) => {
                          return (
                            <div
                              key={emoji}
                              className={styles.item}
                              data-emoji={emoji}
                              onClick={handleItemClick}
                            >
                              {emoji}
                            </div>
                          );
                        })}
                      </SimpleGrid>
                    ) : (
                      <Box pt={16}>
                        <NoData
                          size={100}
                          description={locales.searchNoResults}
                          descriptionProps={{
                            fz: 'sm',
                          }}
                        />
                      </Box>
                    )}
                  </div>
                ) : (
                  data.map((c) => (
                    <div key={c.id}>
                      <Paper
                        py={6}
                        pos={'sticky'}
                        top={-1}
                        style={{ zIndex: 1 }}
                      >
                        <Text fz={'sm'} c={'dimmed'}>
                          {locales.emojiCategory && locales.emojiCategory[c.id]}
                        </Text>
                      </Paper>
                      <SimpleGrid cols={8} spacing={0}>
                        {c.emojis.map((emoji) => (
                          <div
                            key={emoji}
                            className={styles.item}
                            data-emoji={emoji}
                            onClick={handleItemClick}
                          >
                            {emoji}
                          </div>
                        ))}
                      </SimpleGrid>
                    </div>
                  ))
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
