import { Suspense, memo, useCallback, useEffect, useState } from 'react';

import { filesApi } from '@/api/files';
import { showErrorNotification } from '@/api/helpers/showNotifications';
import { getAccessToken } from '@/api/helpers/token';
import { ApiUsers } from '@/api/users';
import { store } from '@/app/store';
import { CUSTOMER } from '@/config/constants';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';
import { useAvatarColor } from '@sinups/agg';
import { Editor, Root } from '@sinups/editor-dsd';
import { isString } from 'lodash-es';

import { BasePageData } from '../CreatePageModal/CreatePageModal';
import { activeUsersSlice } from './slice';

const UNSPLAH_CLIENT_ID = import.meta.env.VITE_UNSPLASH;
const GIPHY_TOKEN = import.meta.env.VITE_GIPHY;
const WSSURL = import.meta.env.VITE_WSS;

const TextEditorComponent = <
  PAGE extends Pick<BasePageData, 'id' | 'content'>,
>({
  page,
  handleContent,
}: {
  page: PAGE;
  handleContent?: (v: string) => void;
}) => {
  const [value, setValue] = useState<string>(page?.content.html || '');
  const [upload] = filesApi.endpoints.upload.useMutation();
  const user = useCurrentUserData();
  const color = useAvatarColor(user?.id ?? '');

  const accessToken = getAccessToken();
  const isNewPage = page?.id === 'new_page';

  // const childPages = usePagesTree(page.id);
  // const childPagesData = useMemo(
  //   () =>
  //     childPages.complexData.map((page) => ({
  //       ...page,
  //       onClick: () => {
  //         const link =
  //           location.origin +
  //           generateLink(AppRoutes.pages, { pageId: page.id });
  //         window.open(link, '_blank')?.focus();
  //       },
  //     })),
  //   [childPages.complexData],
  // );

  const fetchUploadImage = async (
    file: File,
    success: (url: string, id: string) => void,
    error?: (error?: Error) => void,
  ) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('isPublic', 'true');

    return upload(formData)
      .then((res) => {
        if (!res.data) throw new Error('File upload failed');
        success(res.data, res.data.toString());
      })
      .catch((err) => {
        if (error) {
          if (err instanceof Error) {
            error(err);
          } else {
            error(new Error('An unknown error occurred'));
          }
        }
      });
  };

  useEffect(() => {
    setValue(page?.content.html || '');
  }, [page.content]);

  const handleChange = useCallback(
    (value: Root | string) => {
      if (isString(value)) {
        handleContent?.(value);
      }
    },
    [handleContent],
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Editor<ApiUsers.IUser, ComplexHierarchyPageData>
        onChange={handleChange}
        debounce={isNewPage ? 1 : 800}
        fetchUploadImage={fetchUploadImage}
        maxImageSizeError={() =>
          showErrorNotification({
            message: 'Превышен допустимый размер',
          })
        }
        maxImageSize={25}
        initialContent={value}
        outputFormat={'html'}
        mode={'editor'}
        excludeNodes={{
          form: true,
        }}
        language="ru"
        // childPages={childPagesData}
        {...(CUSTOMER === 'DUDOC'
          ? {
              ws:
                isNewPage || !WSSURL
                  ? undefined
                  : {
                      url: `${WSSURL}/?token=${accessToken}`,
                      id: page.id,
                      user: { name: user.name, color, awarenessData: user },
                      getActiveUsers: (users) => {
                        store.dispatch(
                          activeUsersSlice.actions.setActiveUsers(users),
                        );
                      },
                    },
              imagePickerSettings: {
                giphyToken: GIPHY_TOKEN,
                unsplashClientId: UNSPLAH_CLIENT_ID,
              },
            }
          : {})}
      />
    </Suspense>
  );
};

export const TextEditor = memo(TextEditorComponent);
