import { Box, BoxProps } from '@mantine/core';

import styles from './CustomKBD.module.css';

interface CustomKBDProps {
  value: string;
  miw?: number;
  w?: number;
  mr?: number;
  fz?: number;
  h?: number;
  color?: string;
  onClick?: () => void;
  fontFamily?: string;
  fw?: number;
  c?: string;
  br?: number;
  bbw?: number;
}

const CustomKBD = ({
  value,
  miw = 31,
  w = 16,
  mr = 0,
  fz = 12,
  fontFamily = 'Space-Mono',
  fw = 400,
  bbw = 2,
  br = 4,
  onClick = () => {},
  ...props
}: CustomKBDProps & BoxProps) => {
  return (
    <Box
      {...props}
      className={styles.root}
      onClick={onClick}
      py={2}
      miw={miw}
      w={w}
      mr={mr}
      fw={fw}
      fz={fz}
      style={{
        borderRadius: br,
        borderBottomWidth: bbw,
        fontFamily: fontFamily,
      }}
    >
      {value}
    </Box>
  );
};

export default CustomKBD;
