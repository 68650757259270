import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from './helpers/token';

const APP_API = import.meta.env.VITE_APP_API || '';
const BACKEND_TYPE = import.meta.env.VITE_BACKEND_TYPE || '';

const DEFAULT_BASE_URL = BACKEND_TYPE === 'R7' ? '/app' : APP_API;

export const customBaseQuery = (baseUrl = DEFAULT_BASE_URL) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      const accessToken = getAccessToken();

      if (accessToken && BACKEND_TYPE !== 'R7') {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  });

  return baseQuery;
};
