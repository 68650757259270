import { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@/app/store';
import { store } from '@/app/store';
import CustomKBD from '@/components/ui/CustomKBD';
import { Box, Button, Divider, Flex, Modal, Stack, Text } from '@mantine/core';
import { PinInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { pinModalSlice } from './slice';

export const closePinModal = () =>
  store.dispatch(pinModalSlice.actions.close());

export const openPinModal = (
  submitFunc: (pinCode: string, generatedCode: string) => void,
  title: string,
  description: string,
  submitLabel: string,
) =>
  store.dispatch(
    pinModalSlice.actions.open({ submitFunc, title, description, submitLabel }),
  );

const Content = () => {
  const { submitFunc, title, description, submitLabel } = useAppSelector(
    (state) => state.pinModal,
  );

  const [pinCode, setPinCode] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const pinInputRef = useRef<HTMLInputElement | null>(null);

  const form = useForm({
    initialValues: {
      pinCode: '',
    },
  });

  useEffect(() => {
    const randomCode = Math.floor(1000 + Math.random() * 9000).toString();
    setGeneratedCode(randomCode);
  }, []);

  const handleSubmit = () => {
    submitFunc(pinCode, generatedCode);
  };

  return (
    <Box>
      <Flex px={'sm'} justify="space-between" align="center">
        <Box component="h3" fw={400} my={14} fz={14}>
          {title}
        </Box>
        <CustomKBD
          onClick={() => closePinModal()}
          value="Esc"
          bbw={4}
          px={'sm'}
          br={6}
        />
      </Flex>
      <Divider />
      <Text c="dimmed" fz={12} mt={19} mx={'sm'} lh={'14px'}>
        {description}
      </Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack p={'sm'} gap={0}>
          <Flex justify="center" align="center">
            <Text
              size="xl"
              fw={600}
              style={{ letterSpacing: '5px' }}
              fz={32}
              my={12}
            >
              {generatedCode}
            </Text>
          </Flex>

          <Flex justify="center" align="center" mb={'md'}>
            {/* <div onClick={focusFirstInput} style={{ display: 'inline-block' }}> */}
            <PinInput
              ref={pinInputRef}
              length={4}
              value={pinCode}
              onChange={setPinCode}
              styles={{
                pinInput: {
                  marginTop: '12px',
                },
              }}
              radius="sm"
              placeholder="○"
              size="sm"
              gap="md"
            />
            {/* </div> */}
          </Flex>
          <Flex justify={'flex-end'} mt="md">
            <Button
              fullWidth
              w={'auto'}
              type="submit"
              size="sm"
              disabled={pinCode.length !== 4 || pinCode !== generatedCode}
            >
              {submitLabel}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export const PinModal = () => {
  const opened = useAppSelector((state) => state.pinModal.opened);
  return (
    <Modal
      size="md"
      opened={opened}
      centered
      onClose={closePinModal}
      withCloseButton={false}
      p={0}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Content />
    </Modal>
  );
};
