import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showSuccessNotification } from '@/api/helpers/showNotifications';
import { useAppSelector } from '@/app/store';
import { store } from '@/app/store';
import CustomKBD from '@/components/ui/CustomKBD';
import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  PasswordInput,
  Stack,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck } from '@tabler/icons-react';

import CustomCheckbox from './CustomCheckbox';
import { changePasswordModalSlice } from './slice';

import styles from '../Account.module.css';

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const closeChangePasswordModal = () =>
  store.dispatch(changePasswordModalSlice.actions.close());

export const openChangePasswordModal = () =>
  store.dispatch(changePasswordModalSlice.actions.open());

const Content = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const symbols = ['@', '#', '$', '%', '^', '&', '+', '='];
  const form = useForm<FormValues>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const passwordValidation = {
    length: newPassword.length >= 8,
    hasNumber: /\d/.test(newPassword),
    hasLowercase: /[a-z]/.test(newPassword),
    hasUppercase: /[A-Z]/.test(newPassword),
    hasSpecialChar: /[@#$%^&+=]/.test(newPassword),
    matches: newPassword === confirmPassword && confirmPassword.length > 0,
  };

  const handleSubmit = () => {
    closeChangePasswordModal();
    showSuccessNotification({
      title: t('settingsPage.profile.changePasswordSuccessTitle'),
      message: t('settingsPage.profile.changePasswordSuccessDescription'),
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
    });
  };

  return (
    <Box>
      <Flex px={'sm'} justify="space-between" align="center">
        <Box component="h3" fw={400} fz={'sm'}>
          {t('settingsPage.profile.changePassword')}
        </Box>
        <CustomKBD value="Esc" bbw={4} px={'sm'} br={6} />
      </Flex>
      <Divider />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="xs" px={'sm'}>
          <PasswordInput
            mt={18}
            label={t('settingsPage.profile.oldPassword')}
            value={oldPassword}
            classNames={{ label: styles.textInputLabel }}
            onChange={(event) => setOldPassword(event.currentTarget.value)}
            placeholder="Password"
            variant="default"
          />

          <PasswordInput
            mt={11}
            label={t('settingsPage.profile.newPassword')}
            value={newPassword}
            classNames={{ label: styles.textInputLabel }}
            onPaste={(event) => event.preventDefault()}
            onChange={(event) => setNewPassword(event.currentTarget.value)}
            visible={showPassword}
            onVisibilityChange={() => setShowPassword(!showPassword)}
            placeholder="Password"
            variant="default"
          />

          <PasswordInput
            label={t('settingsPage.profile.confirmNewPassword')}
            value={confirmPassword}
            onPaste={(event) => event.preventDefault()}
            classNames={{ label: styles.textInputLabel }}
            onChange={(event) => setConfirmPassword(event.currentTarget.value)}
            visible={showPassword}
            onVisibilityChange={() => setShowPassword(!showPassword)}
            placeholder="Password"
            variant="default"
          />

          <Stack mt="xs" gap={4}>
            <CustomCheckbox
              label={t('settingsPage.profile.passwordMatch')}
              checked={passwordValidation.matches}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordMinLength')}
              checked={passwordValidation.length}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordHasNumber')}
              checked={passwordValidation.hasNumber}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordHasLowercase')}
              checked={passwordValidation.hasLowercase}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordHasUppercase')}
              checked={passwordValidation.hasUppercase}
            />
            <Flex gap={0}>
              <CustomCheckbox
                label={t('settingsPage.profile.passwordHasSpecialChar')}
                checked={passwordValidation.hasSpecialChar}
              />
              <Flex gap={2}>
                {symbols.map((symbol) => (
                  <CustomKBD
                    key={symbol}
                    value={symbol}
                    miw={18}
                    w={18}
                    mr={0}
                    fz={12}
                    h={18}
                    c="dimmed"
                  />
                ))}
              </Flex>
            </Flex>
          </Stack>
          <Flex justify="flex-end">
            <Button
              fullWidth
              type="submit"
              w={'fit-content'}
              size="xs"
              mt="md"
              mb={'sm'}
              disabled={
                !oldPassword.length ||
                !passwordValidation.length ||
                !passwordValidation.hasNumber ||
                !passwordValidation.hasLowercase ||
                !passwordValidation.hasUppercase ||
                !passwordValidation.hasSpecialChar ||
                !passwordValidation.matches
              }
            >
              {t('settingsPage.profile.submit')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export const ChangePasswordModal = () => {
  const opened = useAppSelector((state) => state.changePasswordModal.opened);
  return (
    <Modal
      size="md"
      opened={opened}
      onClose={closeChangePasswordModal}
      withCloseButton={false}
      centered
      p={0}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Content />
    </Modal>
  );
};
