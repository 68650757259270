import { useState } from 'react';

import { Checkbox, CheckboxProps, Stack, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

const CheckboxIcon: CheckboxProps['icon'] = ({ indeterminate, ...others }) =>
  indeterminate ? <IconCheck {...others} /> : <IconCheck {...others} />;
const StepFour = () => {
  const [keepEmail, setKeepEmail] = useState(true);

  return (
    <Stack gap={4}>
      <Text fw={700} size="sm" mt={8}>
        Аккаунт уже почти удалён!
      </Text>
      <Text mt={0} c="dimmed" lh={1.2} size="xs">
        Мы сожалеем, что не смогли оправдать ваши ожидания. Если хотите, вы
        можете оставить свой e-mail в нашей базе и мы сообщим вам о критических
        обновлениях в Dudoc.io.
      </Text>
      <Text mt="sm" c="dimmed" size="xs">
        Торжественно клянемся, что замышляем помощь вам и никакого спама!
      </Text>
      <Checkbox
        mt={20}
        mb={32}
        label="Не удалять e-mail"
        size="xs"
        radius={'sm'}
        checked={keepEmail}
        icon={CheckboxIcon}
        color="black"
        styles={{
          label: {
            fontSize: 12,
            paddingLeft: 8,
          },
        }}
        onChange={(e) => setKeepEmail(e.currentTarget.checked)}
      />
    </Stack>
  );
};

export default StepFour;
