import { Link } from 'react-router-dom';

import { Logotype } from '@/components/ui/Logotype';
import { CUSTOMER } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { Anchor, Group } from '@mantine/core';

import classes from './FooterSimple.module.css';

const links = [
  { link: AppRoutes.userAgreement, label: 'Соглашение' },
  { link: AppRoutes.privacyPolicy, label: 'Конфиденциальность' },
];

export function FooterSimple() {
  const items = links.map((link) => (
    <Anchor
      component={Link}
      to={link.link}
      key={link.label}
      size="sm"
      c={'black'}
      className={classes.link}
    >
      {link.label}
    </Anchor>
  ));

  return (
    <Group className={classes.footer} px="md">
      {CUSTOMER !== 'R7' ? <Logotype minified /> : <Group />}
      <Group className={classes.links}>{items}</Group>
    </Group>
  );
}
