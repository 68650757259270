import { useTranslation } from 'react-i18next';

import { AppRoutes } from '@/config/routes';
import { genericMemo } from '@/helpers/general';
import { generateLink } from '@/helpers/generateLink';
import { Text, Tooltip } from '@mantine/core';
import { IconFile } from '@tabler/icons-react';

import styles from './PublicPageLink.module.css';

export interface PageData {
  id: string;
  icon: string | null;
  title: string;
  order: number;
  onClick?: () => void;
}

export type PageLinkProps<PAGE extends PageData> = {
  page: PAGE;
};

const Component = <PAGE extends PageData>({ page }: PageLinkProps<PAGE>) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.link}
      onClick={() => {
        const link =
          location.origin + generateLink(AppRoutes.share, { pageId: page.id });
        window.open(link, '_blank')?.focus();
      }}
    >
      <div className={styles.icon}>{page.icon || <IconFile size={16} />}</div>

      <Tooltip label={page.title} disabled={!page.title} openDelay={400}>
        <Text truncate className={styles.title} data-empty={!page.title}>
          {page.title || t('noName')}
        </Text>
      </Tooltip>
    </div>
  );
};

export const PublicPageLink = genericMemo(Component);
