import styles from '../Account.module.css';

type CustomCheckboxProps = {
  label: string;
  checked: boolean;
};

const CustomCheckbox = ({ label, checked }: CustomCheckboxProps) => {
  return (
    <div className={styles.checkboxWrapper}>
      <input type="checkbox" id={label} checked={checked} readOnly />
      <label htmlFor={label}>
        {label}
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-check"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#2c3e50"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l5 5l10 -10" />
          </svg>
        </span>
      </label>
    </div>
  );
};

export default CustomCheckbox;
