import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppSelector } from '@/app/store';
import { useMenu } from '@/components/layout/hooks/useMenu';
import CustomKBD from '@/components/ui/CustomKBD';
import { AppRoutes } from '@/config/routes';
import useAccountForm from '@/hooks/useAccountForm';
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  Stepper,
} from '@mantine/core';
import {
  IconCheck,
  IconCheckbox,
  IconClipboardData,
  IconLock,
} from '@tabler/icons-react';

import StepFour from './Steps/StepFour';
import StepOne from './Steps/StepOne';
import StepThree from './Steps/StepThree';
import StepTwo from './Steps/StepTwo';
import { deleteAccountStepperSlice } from './slice';

import styles from './DeleteAccountStepper.module.css';

export const closeDeleteAccountStepper = () =>
  store.dispatch(deleteAccountStepperSlice.actions.close());

export const openDeleteAccountStepper = () =>
  store.dispatch(deleteAccountStepperSlice.actions.open());

const Content = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const { handleRemove } = useAccountForm();
  const navigate = useNavigate();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [modalHeight, setModalHeight] = useState('auto');
  const contentRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useMenu();
  const handleNext = () => {
    if (activeStep === 3) {
      closeDeleteAccountStepper();
      handleRemove();
      navigate(AppRoutes.landing);
      return;
    }

    setActiveStep((current) => (current < 4 ? current + 1 : current));
  };

  useEffect(() => {
    if (activeStep === 0) {
      setIsSubmitDisabled(false);
    }
  }, [activeStep]);
  const updateModalHeight = useCallback(() => {
    const stepsHeight = [196, 392, 313, 303];

    if (contentRef.current) {
      let newHeight = `${stepsHeight[activeStep]}px`;
      if (activeStep === 1 && !isSubmitDisabled) {
        newHeight = '492px';
      }
      setModalHeight(newHeight);
    }
  }, [activeStep, isSubmitDisabled]);

  useEffect(() => {
    updateModalHeight();
  }, [activeStep, isSubmitDisabled, updateModalHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateModalHeight);
    return () => window.removeEventListener('resize', updateModalHeight);
  }, [updateModalHeight]);

  return (
    <Box
      ref={contentRef}
      style={{
        height: isMobile ? 'auto' : modalHeight,
        transition: 'height 0.3s ease',
      }}
    >
      <Flex px={'sm'} justify="space-between" align="center">
        <Box component="h3" fw={400} fz={14} my={14}>
          {'Удаление аккаунта'}
        </Box>
        <CustomKBD
          onClick={() => closeDeleteAccountStepper()}
          value="Esc"
          bbw={4}
          px={'sm'}
          br={6}
        />
      </Flex>
      <Divider />

      <Stepper
        p={'sm'}
        active={activeStep}
        onStepClick={(e) => {
          if (e > activeStep) {
            return;
          }
          setActiveStep(e);
        }}
        classNames={{
          stepIcon: styles.stepIcon,
          stepCompletedIcon: styles.stepCompletedIcon,
          stepWrapper: styles.stepWrapper,
          root: styles.root,
          separator: styles.separator,
          content: styles.content,
          step: styles.step,
        }}
        size="md"
      >
        <Stepper.Step
          completedIcon={<IconCheckbox style={{ width: 12, height: 12 }} />}
          icon={<IconCheckbox style={{ width: 12, height: 12 }} />}
        >
          <StepOne />
        </Stepper.Step>

        <Stepper.Step
          completedIcon={
            <IconClipboardData style={{ width: 12, height: 12 }} />
          }
          icon={<IconClipboardData style={{ width: 12, height: 12 }} />}
        >
          <StepTwo setIsSubmitDisabled={setIsSubmitDisabled} />
        </Stepper.Step>
        <Stepper.Step
          completedIcon={<IconCheck style={{ width: 12, height: 12 }} />}
          icon={<IconLock style={{ width: 12, height: 12 }} />}
        >
          <StepThree setIsSubmitDisabled={setIsSubmitDisabled} />
        </Stepper.Step>
        <Stepper.Step>
          <StepFour />
        </Stepper.Step>

        <Group justify="flex-end" ml={10} gap={10}>
          <Button size="xs" h={32} onClick={closeDeleteAccountStepper}>
            {t('Отмена')}
          </Button>
          <Button
            h={32}
            disabled={isSubmitDisabled}
            variant="default"
            onClick={handleNext}
          >
            {activeStep === 3 ? t('Готово') : t('Дальше')}
          </Button>
        </Group>
      </Stepper>
    </Box>
  );
};
export const DeleteAccountStepper = () => {
  const opened = useAppSelector((state) => state.deleteAccountStepper.opened);
  return (
    <Modal
      size="md"
      centered
      opened={opened}
      onClose={closeDeleteAccountStepper}
      withCloseButton={false}
      p={0}
      styles={{
        content: {
          width: '100%',
          overflow: 'hidden',
        },
        body: {
          padding: 0,
        },
      }}
    >
      <Content />
    </Modal>
  );
};

export default DeleteAccountStepper;
