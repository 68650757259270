import { initReactI18next } from 'react-i18next';

import { CUSTOMER, CustomerType } from '@/config/constants';
import i18n, { Resource } from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import enZod from 'zod-i18n-map/locales/en/zod.json';
import ruZod from 'zod-i18n-map/locales/ru/zod.json';

import en from './locales/en.json';
import ru from './locales/ru.json';

export const defaultLangByAppType: Record<CustomerType, string> = {
  DUDOC: 'en',
  CRUDOC: 'en',
  R7: 'ru',
};

const resourcesByAppType: Record<CustomerType, Resource> = {
  DUDOC: {
    en: {
      translation: en,
      zod: enZod,
    },
    ru: {
      translation: ru,
      zod: ruZod,
    },
  },
  CRUDOC: {
    en: {
      translation: en,
      zod: enZod,
    },
  },
  R7: {
    ru: {
      translation: ru,
      zod: ruZod,
    },
  },
};

i18n.use(initReactI18next).init({
  resources: resourcesByAppType[CUSTOMER],
  lng: defaultLangByAppType[CUSTOMER],
  fallbackLng: defaultLangByAppType[CUSTOMER],
  interpolation: {
    escapeValue: false,
  },
});
z.setErrorMap(zodI18nMap);

export default i18n;
