import { useTranslation } from 'react-i18next';

import { Box, Text } from '@mantine/core';

const StepOne = () => {
  const { t } = useTranslation();

  return (
    <Box pt={7} pb="sm" mb={'sm'}>
      <Text size="lg" fz={14} fw={700}>
        {t('removeAccountModal.deleteAccountForever')}
      </Text>
      <Text c="dimmed" fz={12} lh={1.2} mt={4}>
        {t('removeAccountModal.deleteWarning')}
      </Text>
    </Box>
  );
};

export default StepOne;
