import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { showErrorNotification } from '@/api/helpers/showNotifications';
import { ApiPages, workspacesApi } from '@/api/workspaces';
import { appRoute } from '@/app/routes/AppRoute';
import {
  AppLayout,
  NavbarHeader,
  NavbarScrollableContent,
} from '@/components/layout/AppLayout/AppLayout';
import { useNavbarScroll } from '@/components/layout/AppLayout/useNavbarScroll';
import { MenuItems } from '@/components/layout/Sidebar/components/MenuItems/MenuItems';
import { PublishedPageLinksGroup } from '@/components/layout/Sidebar/components/PageLink/PublishedPageLinksGroup';
import { Logotype } from '@/components/ui/Logotype';
import { NoData } from '@/components/ui/NoData/NoData';
import { PublicPageLink } from '@/components/ui/PublicPageLink/PublicPageLink';
import { APP_NAME } from '@/config/constants';
import { AttachmentsGalleryModal } from '@/features/AttachmentsGalleryModal';
import { CONTENT_ELEMENT_ID, PageBody } from '@/features/PageBody/PageBody';
import { PublicSearchModal } from '@/features/SearchModal/SearchModal';
import { TableOfContents } from '@/features/TableContents/TableContents';
import { PublishedPageProvider } from '@/hooks/useWsProvider';
import { Box, Flex, MantineProvider, Skeleton, Stack } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';

import { ColorThemeSwitcher } from './ColorThemeSwitcher';
import { CryptedLayout } from './CryptedLayout';

function ChildDocsComponent({
  pages,
}: {
  pages: ApiPages.HierarchyPageData[];
}) {
  return (
    <MantineProvider>
      <div>
        {pages.map((page) => (
          <PublicPageLink page={page} key={page.id} />
        ))}
      </div>
    </MantineProvider>
  );
}

export const PublishedPage = () => {
  const { t } = useTranslation();
  const { pageId } = useParams();
  const [pin, setPin] = useState('');
  const [pageUnlocked, setPageUnlocked] = useState(false);
  const [rootId, setRootId] = useState<string | null>();
  const [tree, setTree] = useState<ApiPages.HierarchyPageData>();
  const [data, setData] = useState<ApiPages.GetPublishedPageResponse | null>();
  const [error, setError] = useState(false);

  const [showImage, setShowImage] = useState<string | null>(null);

  const images = useMemo<string[]>(() => {
    const ghost = document.createElement('div');
    const html = data?.document.content.html || '';
    ghost.innerHTML = html;

    const images = [...ghost.querySelectorAll('img')].map((el) => el.src);

    return images;
  }, [data]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (e.target instanceof HTMLImageElement) {
        setShowImage(e.target.src);
      }
    };

    document
      .getElementById(CONTENT_ELEMENT_ID)
      ?.addEventListener('click', handleClick);

    return () => {
      document
        .getElementById(CONTENT_ELEMENT_ID)
        ?.removeEventListener('click', handleClick);
    };
  }, [images]);

  const [getPage, { isLoading }] =
    workspacesApi.endpoints.getPublicPageById.useLazyQuery();

  useEffect(() => {
    if (!pageId) return;
    getPage({ pageId })
      .unwrap()
      .then((res) => {
        setData(res);
        setRootId(res.rootParentId);
      })
      .catch(() => setError(true));
  }, [getPage, pageId]);

  useEffect(() => {
    document.querySelectorAll("div[data-child-docs='true']").forEach((el) => {
      ReactDOM.createRoot(el).render(
        <ChildDocsComponent pages={data?.tree.children || []} />,
      );
    });
  }, [data]);

  const handleUnlock = useCallback(() => {
    if (!pageId) return;
    getPage({ pageId, pin })
      .unwrap()
      .then((res) => {
        setData({ ...res, document: { ...res.document, iscrypted: false } });
        setError(false);
        setPageUnlocked(true);
      })
      .catch((err) => {
        if (err?.data?.code === 'ERR10000') {
          showErrorNotification({
            message: 'Неверный пароль',
          });
        } else {
          setError(true);
        }
      });
  }, [getPage, pageId, pin]);

  useEffect(() => {
    if (!rootId) return;
    getPage({ pageId: rootId })
      .unwrap()
      .then((res) => {
        setTree(res.tree);
      })
      .catch(() => setError(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootId]);

  const debouncedHandleUnlock = useDebouncedCallback(handleUnlock, 400);

  if (error) {
    return (
      <>
        <Helmet>
          <title>Страница не найдена | {APP_NAME}</title>
        </Helmet>
        <Stack
          style={{
            height: '100dvh',
            display: 'flex',
            paddingTop: '10dvh',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NoData
            illustration="👀"
            size={150}
            title={'Страница не найдена'}
            descriptionProps={{ maw: 600 }}
            description={'Возможно, страница была перемещена или удалена.'}
            buttons={[
              {
                children: 'Вернуться на главную',
                onClick: () => appRoute.navigate('/'),
              },
            ]}
          />
        </Stack>
      </>
    );
  }

  if (!data) {
    return (
      <Stack>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    );
  }

  if (data.document.iscrypted && !pageUnlocked) {
    return (
      <>
        <Helmet>
          <title>
            {data.document.icon || ''} {data.document.title || t('noName')} |{' '}
            {APP_NAME}
          </title>
        </Helmet>
        <CryptedLayout
          pin={pin}
          setPin={setPin}
          onUnlock={debouncedHandleUnlock}
          handleUnlock={handleUnlock}
          isLoading={isLoading}
          data={data}
        />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {data.document.icon || ''} {data.document.title || t('noName')} |{' '}
          {APP_NAME}
        </title>
      </Helmet>
      <PublishedPageProvider>
        <AppLayout
          hideUserMenu
          navbar={<Navbar tree={tree} />}
          content={<Content data={data} />}
          header={
            <Flex flex={1} justify="flex-end">
              <ColorThemeSwitcher />
            </Flex>
          }
        />
        <AttachmentsGalleryModal
          showAttach={showImage}
          setShowAttach={setShowImage}
          attachments={images}
        />
        <TableOfContents />
        <PublicSearchModal workspaceId={data.document.workspaceId} />
      </PublishedPageProvider>
    </>
  );
};

const Navbar = ({ tree }: { tree: ApiPages.HierarchyPageData | undefined }) => {
  const { onScroll, showDivider } = useNavbarScroll();
  const [opened, setOpened] = useState<string[]>([]);

  useEffect(() => {
    const ids: string[] = [];

    const addIds = (pages: ApiPages.HierarchyPageData[]) => {
      pages.forEach((p) => {
        ids.push(p.id);
        addIds(p.children);
      });
    };

    if (tree) {
      addIds([tree]);
    }

    setOpened(ids);
  }, [tree]);

  return (
    <>
      <NavbarHeader showDivider={showDivider}>
        <Box pl={8}>
          <Link to={'/'}>
            <Logotype />
          </Link>
        </Box>
      </NavbarHeader>

      <NavbarScrollableContent onScroll={onScroll}>
        <MenuItems />
        <Box p={16}>
          {tree && (
            <PublishedPageLinksGroup
              pages={[tree as any]}
              opened={opened}
              setOpened={setOpened}
              depth={0}
            />
          )}
        </Box>
      </NavbarScrollableContent>
    </>
  );
};

export const Content = ({
  data,
}: {
  data: ApiPages.GetPublishedPageResponse;
}) => {
  const page = data.document;
  return <PageBody page={page} />;
};
