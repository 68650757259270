import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useMenu } from '@/components/layout/hooks/useMenu';
import { AppRoutes } from '@/config/routes';
import { openCreatePageModal } from '@/features/CreatePageModal/CreatePageModal';
import { genericMemo, getLinks } from '@/helpers/general';
import { generateLink } from '@/helpers/generateLink';
import { useActiveLink } from '@/hooks/useActiveLink';
import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconFile,
  IconPlus,
} from '@tabler/icons-react';
import { xor } from 'lodash-es';

import { PageActions } from '../PageActions/PageActions';

import styles from './PageLink.module.css';

export type PageLinkProps<PAGE extends ComplexHierarchyPageData> = {
  page: PAGE;
  opened: string[];
  setOpened: Dispatch<SetStateAction<string[]>>;
  depth: number;
};

const Component = <PAGE extends ComplexHierarchyPageData>({
  page,
  depth,
  opened,
  setOpened,
}: PageLinkProps<PAGE>) => {
  const { t } = useTranslation();
  const { isBurger, close } = useMenu();

  const isOpen = opened.includes(page.id);
  const [actionsOpened, setActionsOpened] = useState(false);

  const isActive = useActiveLink(
    getLinks({
      page,
      isOpen,
      children: page.children?.map((el) => ({ id: el.id })),
    }),
  );

  return (
    <NavLink
      to={generateLink(AppRoutes.pages, { pageId: page.id })}
      key={page.id}
      className={styles.link}
      data-active={isActive}
      data-selected={actionsOpened}
      onClick={() => {
        isBurger && close();
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        setActionsOpened(true);
      }}
    >
      <Flex gap={4} h="100%" pl={depth * 26} flex={1} miw={0}>
        {page.children.length > 0 && (
          <ActionIcon
            size={24}
            variant="subtle"
            color="dark.2"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpened((prev) => xor(prev, [page.id]));
            }}
          >
            {isOpen ? (
              <IconChevronDown size={16} />
            ) : (
              <IconChevronRight size={16} />
            )}
          </ActionIcon>
        )}

        {page.icon || <IconFile size={16} className={styles.icon} />}

        <Tooltip label={page.title || t('noName')} openDelay={400}>
          <Text
            truncate
            className={styles.title}
            style={{ opacity: page.title ? 1 : 0.5 }}
          >
            {page.title || t('noName')}
          </Text>
        </Tooltip>

        <div className={styles.actions}>
          <PageActions
            page={page}
            opened={actionsOpened}
            setOpened={setActionsOpened}
          />
          <Tooltip label={t('addChildDocument')}>
            <ActionIcon
              variant="subtle"
              color="dark.2"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                isBurger && close();
                openCreatePageModal({
                  parentId: page.id,
                  workspaceId: page.workspaceId,
                });
              }}
            >
              <IconPlus size={16} />
            </ActionIcon>
          </Tooltip>
        </div>
      </Flex>
    </NavLink>
  );
};

export const PageLink = genericMemo(Component);
