import { rem } from '@mantine/core';
import { NotificationData, showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { isFunction, isObject } from 'lodash-es';

type MessageType = string | number | NotificationData;

export const showRequestNotifications = <
  Response,
  Request extends Promise<Response> = Promise<Response>,
>(
  request: Request,
  options: {
    notifyId?: string;
    successMsg?: ((res: Response) => MessageType) | MessageType;
    errorMsg?: ((err: any) => MessageType) | MessageType;
  } = {},
) => {
  const { successMsg, errorMsg } = options;

  return request
    .then((res) => {
      if (successMsg) {
        const message = isFunction(successMsg) ? successMsg(res) : successMsg;

        const notifyOpts = {
          id: options.notifyId,
          ...(isObject(message) ? message : { message }),
        };

        showSuccessNotification(notifyOpts);
      }
      return res;
    })
    .catch((err) => {
      if (errorMsg) {
        const message = isFunction(errorMsg) ? errorMsg(err) : errorMsg;

        const notifyOpts = {
          id: options.notifyId,
          ...(isObject(message) ? message : { message }),
        };

        showErrorNotification(notifyOpts);
      }
      return err;
    });
};

export const showSuccessNotification = (props: NotificationData) => {
  showNotification({
    color: 'teal',
    styles: {
      root: props.title
        ? {
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }
        : undefined,
      body: !props.title
        ? {
            display: 'flex',
            alignSelf: 'center',
          }
        : {},
      description: {
        lineHeight: '16px',
      },
      icon: {
        marginInlineEnd: '0px ',
        marginRight: '12px',
        marginLeft: '2px',
      },
    },
    icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
    position: 'top-right',
    ...props,
  });
};

export const showErrorNotification = (props: NotificationData) => {
  showNotification({
    color: 'red',
    ...props,
  });
};
