import { Navigate, createBrowserRouter } from 'react-router-dom';

import { ProtectedRoute } from '@/app/routes/ProtectedRoute';
import { AppRouteLayout } from '@/components/layout/AppRouteLayout/AppRouteLayout';
import { AppRouteSettingsLayout } from '@/components/layout/Settings/AppRouteSettingsLayout';
import { AccountSettings } from '@/components/layout/Settings/Pages/account';
import { LeadsList } from '@/components/layout/Settings/Pages/leads';
import { MembersSettings } from '@/components/layout/Settings/Pages/members';
import { PublishingSettings } from '@/components/layout/Settings/Pages/publishing';
import { WorkspaceSettings } from '@/components/layout/Settings/Pages/workspace';
import {
  CUSTOMER,
  SHOW_CUSTOM_UI,
  SHOW_FORMS,
  SHOW_LANDING,
} from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { AppPage } from '@/pages/AppPage/AppPage';
import { LoginPage } from '@/pages/AuthPages/LoginPage';
import { OAuthPage } from '@/pages/AuthPages/OAuthPage';
import { RegisterPage } from '@/pages/AuthPages/RegisterPage';
import { DocPage } from '@/pages/DocPage/DocPage';
import { ErrorPage } from '@/pages/ErrorPage/ErrorPage';
import { EditFormPage } from '@/pages/FormPage/EditFormPage';
import { FormPage } from '@/pages/FormPage/FormPage';
import { FormsList } from '@/pages/FormPage/FormsList/FormsList';
import { ResultsFormPage } from '@/pages/FormPage/ResultsFormPage';
import { PublicForm } from '@/pages/FormPreview/PublicForm';
import InvitationPage from '@/pages/InvitationPage/Invitation';
import { LandingPage } from '@/pages/LandingPage/LandingPage';
import { NotFoundPage } from '@/pages/NotFoundPage/NotFoundPage';
import { PublishedPage } from '@/pages/PublishedPage/PublishedPage';
import { PrivacyPolicyPage } from '@/pages/Terms/PrivacyPolicyPage';
import { UserAgreementPage } from '@/pages/Terms/UserAgreementPage';
import { TrashPage } from '@/pages/TrashPage/TrashPage';

import RoleBasedRoute from './RoleBasedRoute';

const isPreviewBuild = import.meta.env.VITE_PREVIEW_BUILD === 'true';
const previewUUID = import.meta.env.VITE_PREVIEW_UUID;

export const basename = isPreviewBuild ? `/${previewUUID}/` : '';

const basenameOption = { basename };

export const appRoute = createBrowserRouter(
  [
    ...(CUSTOMER === 'DUDOC'
      ? [
          {
            path: AppRoutes.privacyPolicy,
            element: <PrivacyPolicyPage />,
            ErrorBoundary: ErrorPage,
          },
          {
            path: AppRoutes.userAgreement,
            element: <UserAgreementPage />,
            ErrorBoundary: ErrorPage,
          },
        ]
      : []),

    ...(SHOW_LANDING
      ? [
          {
            path: AppRoutes.landing,
            element: <LandingPage />,
            ErrorBoundary: ErrorPage,
          },
        ]
      : []),

    ...(CUSTOMER === 'R7' || CUSTOMER === 'CRUDOC'
      ? [
          {
            path: AppRoutes.oauth,
            element: <OAuthPage />,
            ErrorBoundary: ErrorPage,
          },
        ]
      : []),

    {
      path: AppRoutes.login,
      element: <LoginPage />,
      ErrorBoundary: ErrorPage,
    },
    {
      path: AppRoutes.register,
      element: <RegisterPage />,
      ErrorBoundary: ErrorPage,
    },
    {
      path: AppRoutes.share,
      element: <PublishedPage />,
      ErrorBoundary: ErrorPage,
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <AppRouteLayout />
        </ProtectedRoute>
      ),
      ErrorBoundary: ErrorPage,
      children: [
        { path: AppRoutes.formsPageEdit, element: <EditFormPage /> },
        { path: AppRoutes.formsPageResults, element: <ResultsFormPage /> },
        { path: AppRoutes.formsPage, element: <FormPage /> },

        ...(SHOW_FORMS
          ? [
              {
                path: AppRoutes.formsList,
                element: <FormsList />,
              },
            ]
          : []),

        { path: AppRoutes.pages, element: <DocPage /> },
        { path: AppRoutes.trash, element: <TrashPage /> },
        { path: '/', index: true, element: <AppPage /> },
      ],
    },
    {
      path: AppRoutes.invitation,
      element: <InvitationPage />,
      ErrorBoundary: ErrorPage,
    },
    {
      path: AppRoutes.formPreview,
      element: <PublicForm />,
      ErrorBoundary: ErrorPage,
    },
    {
      path: AppRoutes.settings,
      element: (
        <ProtectedRoute>
          <AppRouteSettingsLayout />
        </ProtectedRoute>
      ),
      ErrorBoundary: ErrorPage,
      children: [
        {
          path: '',
          element: (
            <Navigate
              to={
                CUSTOMER === 'DUDOC'
                  ? AppRoutes.settingsAccount
                  : AppRoutes.settingsWorkspace
              }
            />
          ),
        },

        ...(CUSTOMER === 'DUDOC'
          ? [{ path: 'account', element: <AccountSettings /> }]
          : []),
        {
          path: 'members',
          element: (
            <RoleBasedRoute
              requiredRoles={['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_PARTICIPANT']}
            >
              <MembersSettings />
            </RoleBasedRoute>
          ),
        },
        {
          path: 'workspace',
          element: (
            <RoleBasedRoute requiredRoles={['ROLE_OWNER']}>
              <WorkspaceSettings />
            </RoleBasedRoute>
          ),
        },
        {
          path: 'publish',
          element: SHOW_CUSTOM_UI ? (
            <RoleBasedRoute requiredRoles={['ROLE_OWNER']}>
              <PublishingSettings />
            </RoleBasedRoute>
          ) : (
            <NotFoundPage />
          ),
        },
        {
          path: 'leads',
          element: (
            <RoleBasedRoute requiredRoles={['ROLE_OWNER']}>
              <LeadsList />
            </RoleBasedRoute>
          ),
        },
      ],
    },
    { path: '*', element: <NotFoundPage /> },
  ],
  basenameOption,
);
